<template>
  <div class="main-container">
    <Header></Header>
    <div class="content-container" style="width: 100%">
      <!--子频道导航条-->
      <div class="sub-menu-wrap">
        <el-menu
          :default-active="activeIndex"
          class="channel-menu"
          mode="horizontal"
          @select="handleMenuSelect"
        >
          <el-menu-item
            v-for="item in subChannelList"
            :index="item.channelId.toString()"
            :key="item.channelId"
            >{{ item.name }}
          </el-menu-item>
        </el-menu>
      </div>

      <!-- 内容区域 -->
      <div class="sub-content-container">
        <div class="content-box">
          <template v-if="currChannel.displayMode == 1">
            <ArticleDetail
              :articleId="currChannel.articleId"
              :showBreadcrumb="false"
            ></ArticleDetail>
          </template>
          <template v-else>
            <ArticleList :channelId="currChannel.channelId"></ArticleList>
          </template>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import SubHeader from "./components/subheader";
import ArticleList from "./components/articlelist";
import ArticleDetail from "./components/articledetail";

//import Default from "./defaultpage";
//import Leader from "./leader";
//import Policy from "./policy";
//import Address from "./address";
//import Inspection from "./inspection";
import { getChildChannelByAlias } from "@/api/cms";


export default {
  components: { Header, Footer, ArticleList, ArticleDetail },
  props: {},
  data() {
    return {
      subChannelList: [],
      activeIndex: undefined,

      currArticleId: undefined,
      currChannel: {},
    };
  },
  methods: {
    
    getChannelItems() {
      getChildChannelByAlias("xinxigongkai").then((res) => {
        if (res.code == 200 && res.data != null && res.data.length > 0) {
          this.subChannelList = res.data;
          if (this.activeIndex == undefined || this.activeIndex == null) {
            this.activeIndex = this.subChannelList[0].channelId + "";
          }
          this.handleMenuSelect(this.activeIndex, this.activeIndex);
        } else {
          this.subChannelList = [];
        }
      });
    },

    handleMenuSelect(index, indexPath) {
      //this.activeIndex = index;
      this.$router.push({
        path: "/publicity?id=" + index,
      });
      this.currChannel = this.getChannelInfo(index);
    },

    getChannelInfo(id) {
      let retItem = {};
      this.subChannelList.forEach((item, index) => {
        if (item.channelId == id) {
          retItem = item;
          return;
        }
      });
      return retItem;
    },
  },
  created() {
    this.activeIndex = this.$route.query.id;
    this.getChannelItems();
  },
  mounted() {},
  watch: {},
};
</script>
<style scoped>
.sub-menu-wrap {
  width: 100%;  
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: solid 1px #e6e6e6;
}
.el-menu {
  border-bottom: none;
  width: 800px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.el-menu--horizontal > .el-menu-item {
  height: 40px;
  line-height: 40px;
  color: #606266;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 1px solid #9a0000;
  color: #9a0000;
}
.sub-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;  
  padding: 10px;
}
.content-box {
  width: 800px;
}
</style>
