import { render, staticRenderFns } from "./bookingform.vue?vue&type=template&id=50e6c528&scoped=true"
import script from "./bookingform.vue?vue&type=script&lang=js"
export * from "./bookingform.vue?vue&type=script&lang=js"
import style0 from "./bookingform.vue?vue&type=style&index=0&id=50e6c528&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50e6c528",
  null
  
)

export default component.exports