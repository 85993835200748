<template>
    <div class="articlelist-container">
        <div class="article-list-wrap" v-if="articleList.length > 0">
            <div class="article-list">
                <div class="article-list-item" v-for="article in articleList"
                    :key="article.articleId">
                    <img
                        class="title-icon"
                        :src="require('@/assets/img/pic03.gif')"
                    />
                    <div class="title">
                        <!--
                        <router-link
                            :to="`/article?id=${article.articleId}&from=publicity`"
                            tag="a"
                            target="_blank"
                            >{{ article.title }}
                        </router-link>
                    -->
                        <el-link @click="viewArticle(article)" :underline="false">{{article.title}}</el-link>
                    </div>
                    <div class="pubtime-box">
                        <el-link @click="viewArticle(article)" :underline="false" class="pubtime">[{{ parseTime(article.pubTime, '{y}-{m}-{d}') }}]</el-link>
                        <!--
                        <router-link
                        :to="`/article?id=${article.articleId}&from=publicity`"
                        tag="a"
                        
                        >[{{ parseTime(article.pubTime, '{y}-{m}-{d}') }}]</router-link>
                    -->
                    </div>
                </div>
            </div>
            <div style="text-align:right;margin:10px 0px;">
                <el-pagination
                  v-show="totals > 0"
                  background
                  layout="total, sizes, prev, pager, next"
                  :total="totals"
                  :page-sizes="[10, 20, 50]"        
                  :current-page.sync="queryParams.pageNum"
                  :page-size.sync="queryParams.pageSize"
                  @current-change="getArticleList"
                  @size-change="handlePageSizeChange"
                  >
                </el-pagination>
            </div>
        </div>
        <div v-else>                
            <el-empty :image-size="180"></el-empty>                
        </div>
    </div>
</template>
<script>
    import { parseTime } from "@/utils/wmeeting";
    import {getChannelByAlias, queryArticleList} from "@/api/cms";
    
    export default {
        components: { },
        props: {
            channelAlias: String,
            channelId: undefined
        },
        data() {
            return {
                currChannelAlias: undefined,

                loading: false,
                channel: {},
                articleList: [],
                totals: 0,
                queryParams: {
                    title: undefined,
                    channelId: undefined,
                    pageNum: 1,
                    pageSize: 10,
                }
            };
        },
        methods: {
            parseTime,            

            // 获取文章列表
            getArticleList(){
                if(this.queryParams.channelId == null || this.queryParams.channelId == undefined){
                    return;
                }
                
                this.loading = true;
                queryArticleList(this.queryParams).then((res)=>{                    
                    if(res.code == 200 && res.data != null){
                        this.articleList = res.data.list;
                        this.totals = res.data.totals;                        
                    }else{
                        this.articleList = [];
                    }   
                    this.loading = false;
                }).catch(()=>{
                    this.articleList = [];    
                    this.loading = false;                
                });
            },

            handlePageSizeChange(val){
                this.queryParams.pageSize = val;
                if(this.queryParams.page * val > this.totals){
                    this.queryParams.page = 1;
                }
                
                this.getArticleList();
            },            

            viewArticle(article){
                
                let path = "/article?id=" + article.articleId +"&from=publicity";
                window.open(path, '_blank');
            }
        },
        created() {
            this.queryParams.channelId = this.channelId;
            this.$nextTick(()=>{
                this.getArticleList();
            });
            
        },
        mounted() {
          
        },
        watch: {
            channelId(val){
                this.queryParams.channelId = val;
                this.getArticleList();
            }
        },
}
</script>
<style scoped>
.article-list-item{
    display: flex;
    flex-direction: row;    
    align-items: center;
    padding: 8px 5px;
    line-height: 36px;
    border: solid #e8dbd4;
    border-width: 0 0 1px;
}
.article-list-item .title-icon{
    margin-right: 10px;
}
.article-list-item .title{
    flex-grow: 1;
}
.article-list-item .pubtime{
    font-size: 12px;
    width: 80px;
    color: #9e9e9e;
}

a{
    color: #000000;
}
a:hover, a:focus {
    color: #9a0000 !important;
    text-decoration: none !important;
}
</style>
