<template>
    <!--子频道导航条-->
    <div class="sub-menu-wrap">
        <el-menu :default-active="currIndex"
            class="channel-menu"
            mode="horizontal"
            @select="handleMenuSelect">
            <el-menu-item v-for="item in subChannelList"
                :index="item.alias"
                :key="item.channelId">{{ item.name }}
            </el-menu-item>
        </el-menu>
    </div>
</template>
<script>
    //import Header from "@/components/header";
    import {getChildChannelByAlias} from "@/api/cms";
    import { getPathByChannelAlias} from "@/utils/cmsUtil";
    
    export default {
        components: { },
        props: {
            activeIndex: String
        },
        data() {
            return {                
                currIndex: null,
                subChannelList : [],
            };
        },
        methods: {
            getChannelItems(){
                getChildChannelByAlias("xinxigongkai").then(res => {
                    if(res.code == 200 && res.data != null){
                        this.subChannelList = res.data;
                        if(this.activeIndex == undefined || this.activeIndex == null){
                            this.currIndex = this.subChannelList[0].alias;
                            this.onSelectChannel(this.currIndex);
                        }
                    }else{
                        this.subChannelList = [];
                    }
                });
            },

            handleMenuSelect(index, indexPath){
                this.currIndex = index;     
                   
                this.$router.push({
                    path: '/publicity?alias=' + this.currIndex
                });        
                //let path = getPathByChannelAlias(index);                
                //this.$router.push({
                //    path: path                    
                //});
                this.onSelectChannel(this.currIndex);
                
        },

        onSelectChannel(alias){
            // 通知父级页面频道切换
            this.$emit('selectChannel', alias);
        },
            
        },
        created() {
            
            this.currIndex = this.activeIndex;
            this.getChannelItems();
            //this.$nextTick(()=>{
            //    this.getChannelItems();
            //});
        },
        mounted() {
          
        },
        watch: {            
        },
}
</script>
<style scoped>
.sub-menu-wrap{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: solid 1px #e6e6e6;
}
.el-menu{
    border-bottom: none;
    width: 800px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.el-menu--horizontal>.el-menu-item{
    height: 40px;
    line-height: 40px;
    color: #606266;
}
.el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: 1px solid #9a0000;
    color: #9a0000;    
}
</style>
